import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('ActiveUsersReport', [
    '$injector',
    $injector => {
        const Report = $injector.get('Report');

        // eslint-disable-next-line func-names
        return Report.subclass(function () {
            // must set _alias so reports_overview_dir can setup the selectize
            this._alias = this.alias('ActiveUsersReport');
            this.$$titleKey = 'report_title_active_users';

            this.availableFilterKlasses = [
                $injector.get('EmailNameFilter'),
                $injector.get('SignUpCodeFilter'),
                $injector.get('InstitutionFilter'),
                $injector.get('CohortFilter'),
                $injector.get('RoleFilter'),
                $injector.get('GroupFilter'),
            ];

            this.availableGroupByIdentifiers = ['sign_up_code', 'institution_name', 'role_name', 'group_name'];
        });
    },
]);
