import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('RoleFilter', [
    '$injector',
    $injector => {
        const ListFilter = $injector.get('ListFilter');

        return ListFilter.subclass(function () {
            this.alias('RoleFilter');
            this.$$placeholderKey = 'filter_placeholder_roles';
        });
    },
]);
