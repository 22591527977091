import angularModule from 'Reports/angularModule/scripts/reports_module';
import template from 'Reports/angularModule/views/reports_user_course.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import moment from 'moment-timezone';
import { getProgramInclusion } from 'Users';
import { pick, cloneDeep } from 'lodash/fp';
import { streamIsManuallyUnlocked, willNotGraduate } from 'ProgramInclusion';
import { unclaimedAdminExamVerificationRecordForStream } from 'ExamVerification';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('reportsUserCourse', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const $http = $injector.get('$http');
        const $window = $injector.get('$window');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                stream: '<',
                userLessonProgressReport: '<',
                started: '<?',
                user: '<',
            },
            link(scope) {
                // This is needed to use Math in bindings -- http://stackoverflow.com/a/12740497/1747491
                scope.Math = window.Math;
                const translationHelper = new TranslationHelper('reports.reports');

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                function setLessonDetails() {
                    scope.lessonDetails = {};
                    scope.streamLessonProgressMap = {};
                    scope.streamLessonStartedMap = {};
                    scope.streamLessonCompletedMap = {};
                    // Grab the needed lesson progress report rows
                    scope.stream.lessons.forEach(lesson => {
                        const lessonProgressReportRow = scope.userLessonProgressReport.rowForUserIdAndLocalePackId(
                            scope.user.id,
                            lesson.localePackId,
                        );
                        const lesson_progress = lesson.lesson_progress;
                        scope.lessonDetails[lesson.localePackId] = {
                            reportRow: lessonProgressReportRow,
                            completed: !!lesson_progress?.completed_at,
                            started: !!lesson_progress?.started_at,
                        };

                        const lastLessonResetAt =
                            lessonProgressReportRow &&
                            scope.userLessonProgressReport.getTabularRowValue(
                                lessonProgressReportRow,
                                'last_lesson_reset_at',
                                'raw',
                            );
                        if (lastLessonResetAt) {
                            scope.lastLessonResetAt = Math.max(scope.lastLessonResetAt || 0, lastLessonResetAt);
                        }

                        scope.userLessonProgressReport.tabular_data.forEach(row => {
                            if (
                                lesson.localePackId ===
                                scope.userLessonProgressReport.getTabularRowValue(row, 'locale_pack_id', 'raw')
                            ) {
                                scope.streamLessonProgressMap[lesson.localePackId] = row;
                                scope.streamLessonStartedMap[lesson.localePackId] =
                                    scope.userLessonProgressReport.getTabularRowValue(row, 'started_at', 'raw');
                                scope.streamLessonCompletedMap[lesson.localePackId] =
                                    scope.userLessonProgressReport.getTabularRowValue(row, 'completed_at', 'raw');
                            }
                        });
                    });
                }

                setLessonDetails();

                // FIXME: ideally, this would be true if the stream had ever been
                // completed (even if it has been reset). That would be most consistent.
                // For now, however, this is hard to do because we don;t have an etl table
                // for stream progress.  See also getStreamCompletedTimestamp below
                scope.isCompleted = () => scope.stream.complete;

                scope.hasStreamProgress = () => !!scope.stream.lesson_streams_progress?.started_at;

                scope.resetStream = () => {
                    if (!$window.confirm(translationHelper.get('reset_course_confirm'))) {
                        return;
                    }

                    if (scope.stream.lesson_streams_progress) {
                        scope.stream.lesson_streams_progress.destroy().then(() => {
                            setLessonDetails();

                            // a bit of a hack, but make this show up right away
                            scope.lastLessonResetAt = new Date().getTime() / 1000;

                            // Continuing the hack, reset assessment scores and lesson finish count immediately
                            scope.stream.lessons.forEach(lesson => {
                                const lessonProgressReportRow =
                                    scope.userLessonProgressReport.rowForUserIdAndLocalePackId(
                                        scope.user.id,
                                        lesson.localePackId,
                                    );
                                if (lessonProgressReportRow && lesson.assessment) {
                                    scope.userLessonProgressReport.updateTabularRowValue(
                                        lessonProgressReportRow,
                                        'average_assessment_score_best',
                                        null,
                                    );
                                    scope.userLessonProgressReport.updateTabularRowValue(
                                        lessonProgressReportRow,
                                        'average_assessment_score_first',
                                        null,
                                    );
                                    scope.userLessonProgressReport.updateTabularRowValue(
                                        lessonProgressReportRow,
                                        'assessment_finish_count',
                                        null,
                                    );
                                }
                            });
                        });
                    }
                };

                scope.resetTimer = () => {
                    if (!$window.confirm(translationHelper.get('reset_timer_confirm'))) {
                        return;
                    }

                    scope.stream.lesson_streams_progress.time_runs_out_at = null;
                    scope.stream.lesson_streams_progress.save();
                };

                scope.getStreamStartedTimestamp = () => {
                    let started;
                    Object.values(scope.streamLessonStartedMap).forEach(timestamp => {
                        if (!started || timestamp < started) {
                            started = timestamp;
                        }
                    });

                    return started;
                };

                scope.getStreamCompletedTimestamp = () => {
                    let completed;

                    // We're assuming that the completed at is the last lesson complete at.
                    // Really, we should get this from an etl table, but there is none.  See
                    // isCompleted above.
                    Object.values(scope.streamLessonCompletedMap).forEach(timestamp => {
                        if (!completed || timestamp > completed) {
                            completed = timestamp;
                        }
                    });

                    return completed;
                };

                scope.getTotalTime = () => {
                    let totalTime = 0;

                    Object.entries(scope.streamLessonStartedMap).forEach((localePackId, startedTimestamp) => {
                        const completedTimestamp = scope.streamLessonCompletedMap[localePackId];
                        if (completedTimestamp) {
                            totalTime += completedTimestamp - startedTimestamp;
                        }
                    });

                    return totalTime;
                };

                //-----------------------------------
                // Manual authentication (proctoring)
                //-----------------------------------

                scope.manualAuthenticationCouldApply = () => {
                    if (!scope.currentUser.hasAdminAccess) {
                        return false;
                    }

                    // proctoring applies to any exam and any stream explicitly marked as requires_proctoring.
                    if (!scope.stream.exam && !scope.stream.requires_proctoring) {
                        return false;
                    }

                    if (scope.stream.complete) {
                        return false;
                    }

                    if (!scope.user.relevantCohort?.requiresProctoring) {
                        return false;
                    }

                    if (scope.stream.coming_soon) {
                        return false;
                    }

                    // Note that the above logic will still show the AUTHENTICATE button for many exams
                    // that aren't actually launchable via ContentAccessHelper (because the launch window
                    // has not opened yet, or progress prerequisites are not met, etc.).  But inadvertently
                    // authenticating an unlaunchable exam isn't very harmful or dangerous, so we err on
                    // the side of showing the link when it might not apply. See also comment below about
                    // the prospect of actually using ContentAccessHelper.canLaunch() in this context, for
                    // improved accuracy here.

                    return true;
                };

                scope.streamIsManuallyAuthenticated = () => {
                    if (!scope.manualAuthenticationCouldApply()) {
                        return false;
                    }

                    if (scope.hasBeenManuallyAuthenticated) {
                        // we clicked the button ourselves
                        return true;
                    }

                    return !!unclaimedAdminExamVerificationRecordForStream(scope.user, scope.stream);
                };

                scope.streamCanBeManuallyAuthenticated = () => {
                    if (!scope.manualAuthenticationCouldApply()) {
                        return false;
                    }

                    if (scope.streamIsManuallyAuthenticated()) {
                        return false;
                    }

                    return true;
                };

                scope.manuallyAuthenticateStream = () => {
                    if (!$window.confirm(translationHelper.get('manually_authenticate_stream_confirm'))) {
                        return;
                    }

                    scope.manuallyAuthenticatingStream = true;

                    $http
                        .post(`${$window.ENDPOINT_ROOT}/api/admin_actions/user/create_admin_exam_verification.json`, {
                            user_id: scope.user.id,
                            stream_locale_pack_id: scope.stream.localePackId,
                        })
                        .then(() => {
                            scope.hasBeenManuallyAuthenticated = true;
                        })
                        .finally(() => {
                            scope.manuallyAuthenticatingStream = false;
                        });
                };

                //-----------------------------
                // Stream unlocking/locking
                //-----------------------------

                const lockStreamLocaleKey = 'lock';
                const lockStreamConfirmLocaleKey = 'lock_course_confirm';
                const unlockStreamLocaleKey = 'unlock';
                const unlockStreamConfirmLocaleKey = 'unlock_course_confirm';

                scope.streamCanBeLockedOrUnlocked = () => {
                    if (!scope.currentUser.hasAdminAccess) {
                        return false;
                    }

                    if (!scope.stream.exam) {
                        return false;
                    }

                    if (scope.stream.complete) {
                        return false;
                    }

                    // It may seem like we could just check `inProgress` here and be done with it,
                    // but an admin may have reset the timer, in which case `inProgress` would be
                    // true, but the timer may not have actually started again.
                    if (scope.stream.inProgress && scope.stream.timerIsStarted) {
                        return false;
                    }

                    // Note that even if the program inclusion recognizes the stream as unlocked via the
                    // unlockedStreams array, if the stream is marked as coming_soon, the ContentAccessHelper
                    // locks the stream and prevents the user from launching it. So we disable the unlock/lock
                    // button to not be misleading.
                    if (scope.stream.coming_soon) {
                        return false;
                    }

                    const programInclusion = getProgramInclusion(scope.user);
                    if (!programInclusion || willNotGraduate(programInclusion)) {
                        return false;
                    }

                    // If an admin has unlocked all exam streams for the user via the disableExamLocking flag,
                    // unlocking the stream via the unlockedStreams array is no longer necessary.
                    if (programInclusion.disableExamLocking) {
                        return false;
                    }

                    // Ideally, we'd also check at this point here if the ContentAccessHelper says the user
                    // canLaunch the stream normally without an admin needing to manually unlock it for them
                    // via the unlockedStreams array and return false if that's the case, but it would require
                    // an additional API call to ensure that Playlist#getCachedForLocalePackId returns the
                    // appropriate thing, which is used internally by ContentAccessHelper. Maybe we can add
                    // that when we convert this to React and start using RTKQuery?
                    // if (
                    //     ContentAccessHelper.canLaunch(scope.stream, scope.user) &&
                    //     !streamIsManuallyUnlocked(programInclusion, scope.stream.localePackId)
                    // ) {
                    //     return false;
                    // }

                    return true;
                };

                scope.streamLockingCtaLocaleKey = () => {
                    if (!scope.streamCanBeLockedOrUnlocked(scope.stream)) {
                        return null;
                    }

                    return streamIsManuallyUnlocked(getProgramInclusion(scope.user), scope.stream.localePackId)
                        ? lockStreamLocaleKey
                        : unlockStreamLocaleKey;
                };

                const unlockedStreams = (programInclusion, isUnlocking) => {
                    // Create a clone to serve as a proxy so that we don't soil the original
                    let unlockedStreamsClone = cloneDeep(programInclusion.unlockedStreams);

                    if (isUnlocking) {
                        const unlockedUntil = moment().tz('America/New_York').add(2, 'weeks').endOf('day').valueOf();
                        const streamEntry = unlockedStreamsClone.find(
                            entry => entry.localePackId === scope.stream.localePackId,
                        );

                        // If the stream was previously unlocked and the grace period expired,
                        // we can just bump it on the existing stream entry.
                        if (streamEntry) {
                            streamEntry.unlockedUntil = unlockedUntil;
                        }
                        // Otherwise, we need to add it to the unlockedStreams array
                        else {
                            unlockedStreamsClone.push({
                                localePackId: scope.stream.localePackId,
                                unlockedUntil,
                            });
                        }
                    } else {
                        unlockedStreamsClone = unlockedStreamsClone.filter(
                            entry => entry.localePackId !== scope.stream.localePackId,
                        );
                    }

                    return unlockedStreamsClone;
                };

                scope.updateStreamLocking = () => {
                    if (!scope.streamCanBeLockedOrUnlocked(scope.stream)) {
                        return;
                    }

                    const lockingCtaLocaleKey = scope.streamLockingCtaLocaleKey();
                    const isUnlocking = lockingCtaLocaleKey === unlockStreamLocaleKey;
                    const confirmationLocaleKey = isUnlocking
                        ? unlockStreamConfirmLocaleKey
                        : lockStreamConfirmLocaleKey;

                    if (!$window.confirm(translationHelper.get(confirmationLocaleKey))) {
                        return;
                    }

                    scope.updatingStreamLocking = true;
                    const programInclusion = getProgramInclusion(scope.user);

                    $http
                        .post(
                            `${$window.ENDPOINT_ROOT}/api/admin_actions/program_inclusion/update_unlocked_streams.json`,
                            {
                                record_id: programInclusion.id,
                                unlocked_streams: unlockedStreams(programInclusion, isUnlocking),
                            },
                        )
                        .then(response => {
                            scope.user.copyAttrs(pick(['program_inclusions'], response.data.contents.user));
                        })
                        .finally(() => {
                            scope.updatingStreamLocking = false;
                        });
                };
            },
        };
    },
]);
