import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('TimeOnTaskSingleUserReport', [
    '$injector',
    $injector => {
        const Report = $injector.get('Report');

        return Report.subclass(function () {
            // must set _alias so reports_overview_dir can setup the selectize
            this._alias = this.alias('TimeOnTaskSingleUserReport');
            this.$$titleKey = 'report_y_axis_time_on_task_min';
            this.prototype.chartType = 'bar';

            // since you can't see this, should we just leave it out?
            // maybe availableFilters is really visible filters?
            // this.availableFilterKlasses = [
            //     $injector.get('UserIdFilter')
            // ];
        });
    },
]);
