import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('UserLessonProgressReport', [
    '$injector',
    $injector => {
        const BaseTabularReport = $injector.get('BaseTabularReport');

        // eslint-disable-next-line func-names
        return BaseTabularReport.subclass(function () {
            // Note: see server report model for reference of expected fields
            this.addColumns([
                ['started_at', 'th_started', 'dateWithDetails'],
                ['completed_at', 'th_completed', 'dateWithDetails'],
            ]);

            this.addGroupedColumns('th_assessment_group', [
                ['average_assessment_score_first', 'th_assessment_score_first', 'percentOrDash'],
                ['average_assessment_score_best', 'th_assessment_score_best', 'percentOrDash'],
                ['assessment_finish_count', 'th_assessment_attempts_total', 'numberOrDash'],
            ]);

            this.addHiddenColumns(['user_id', 'locale_pack_id', 'last_lesson_reset_at']);

            // must set _alias so reports_overview_dir can setup the selectize
            this._alias = this.alias('UserLessonProgressReport');
            this.$$titleKey = 'report_title_users'; // Unused, but we currently require it for all reports

            Object.defineProperty(this.prototype, 'rowsByUserIdAndLocalePackId', {
                get() {
                    const self = this;

                    if (
                        !this.$$rowsByUserIdAndLocalePackId ||
                        this.$$rowsByUserIdAndLocalePackId.sourceData !== this.tabular_data
                    ) {
                        this.$$rowsByUserIdAndLocalePackId = _.keyBy(
                            this.tabular_data,
                            row =>
                                `${self.getTabularRowValue(row, 'user_id', 'raw')}.${self.getTabularRowValue(
                                    row,
                                    'locale_pack_id',
                                    'raw',
                                )}`,
                        );

                        this.$$rowsByUserIdAndLocalePackId.sourceData = this.tabular_data;
                    }
                    return this.$$rowsByUserIdAndLocalePackId;
                },
            });

            return {
                rowForUserIdAndLocalePackId(userId, localePackId) {
                    return this.rowsByUserIdAndLocalePackId[`${userId}.${localePackId}`];
                },
                updateTabularRowValue(row, columnKey, value) {
                    const columnIndex = this.INDEXES_FOR_COLUMNS[columnKey];
                    if (columnIndex !== undefined) {
                        row[columnIndex] = value;
                    }
                },
            };
        });
    },
]);
