import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('PlayerLessonSessionsReport', [
    '$injector',
    $injector => {
        const BaseTabularReport = $injector.get('BaseTabularReport');
        const ReportDateRange = $injector.get('ReportDateRange');

        return BaseTabularReport.subclass(function () {
            // Note: see server report model for reference of expected fields
            this.addColumns([
                ['user_name', 'th_name'],
                ['account_id', 'th_account_id', 'accountIdLink'],
                ['client_type', 'th_client_type', 'clientType'],
                ['lesson_stream_title', 'th_course'],
                ['lesson_title', 'th_lesson', 'withLessonLocale'],
                ['started_at', 'th_started', 'time'],
                ['total_lesson_time', 'th_total_time', 'amDurationFormat'],
                ['started_in_this_session', 'th_started_in_this_session', 'bool'],
                ['completed_in_this_session', 'th_completed_in_this_session', 'bool'],
            ]);

            this.addHiddenColumns(['user_id', 'lesson_locale']);

            // must set _alias so reports_overview_dir can setup the selectize
            this._alias = this.alias('PlayerLessonSessionsReport');
            this.$$titleKey = 'report_title_player_lesson_sessions';

            this.availableGroupByIdentifiers = [];

            this.addFilterKlasses([
                $injector.get('CourseFilter'),
                // $injector.get('LessonFilter'), // removing this because it's ugly and brings up issues
                $injector.get('LessonLocaleFilter'),
                $injector.get('ClientTypeFilter'),
            ]);

            return {
                getDefaultDateRange() {
                    return ReportDateRange.new({
                        unit: 'day',
                        type: 'last',
                        value: 1,
                    });
                },
            };
        });
    },
]);
