import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('CourseStartedFilter', [
    '$injector',
    $injector => {
        const ListFilter = $injector.get('ListFilter');

        return ListFilter.subclass(function () {
            this.alias('CourseStartedFilter');
            this.$$placeholderKey = 'filter_placeholder_courses_started';
            this.availableToInstitutionalReportsViewers = true;
        });
    },
]);
