import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('HasCurrentReportMixin', [
    '$injector',
    $injector => {
        const REPORT_DATA_PARAM = 'report_data';
        const Report = $injector.get('Report');
        const $location = $injector.get('$location');
        const $timeout = $injector.get('$timeout');
        const $rootScope = $injector.get('$rootScope');
        const ConfigFactory = $injector.get('ConfigFactory');
        const TransientClientStorage = $injector.get('TransientClientStorage');

        return {
            onLink(scope, defaultReportType, opts = {}) {
                scope.HasCurrentReportMixinOptions = {
                    saveInQueryParam: opts.saveInQueryParam !== false,
                };

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.reload = () => {
                    // write the current report to the query param
                    if (scope.HasCurrentReportMixinOptions.saveInQueryParam) {
                        const currentReportJSON = scope.currentReport.toJson();
                        $location.search(REPORT_DATA_PARAM, currentReportJSON);
                        TransientClientStorage.setItem(scope.currentReport.report_type, currentReportJSON);
                    } else {
                        $location.search(REPORT_DATA_PARAM, null);
                        TransientClientStorage.removeItem(scope.currentReport.report_type);
                    }
                    scope._reloadDataForCurrentReport();
                };

                scope.clear = () => {
                    const alias = scope.currentReport
                        ? scope.currentReport.constructor.alias()
                        : scope.defaultReportType;
                    const klass = Report.reportTypes[alias];
                    scope.currentReport = klass.newForUser($rootScope.currentUser);

                    if (scope.HasCurrentReportMixinOptions.saveInQueryParam) {
                        $location.search(REPORT_DATA_PARAM, null);
                        TransientClientStorage.removeItem(scope.currentReport.report_type);
                    }
                };

                scope._readCurrentReportFromQueryOrStorage = () => {
                    if (!scope.HasCurrentReportMixinOptions.saveInQueryParam) {
                        scope.clear();
                        return;
                    }

                    const paramsJson =
                        $location.search()[REPORT_DATA_PARAM] ||
                        TransientClientStorage.getItem(scope.defaultReportType);
                    const params = paramsJson && paramsJson !== 'undefined' ? JSON.parse(paramsJson) : undefined;

                    if (!params) {
                        scope.clear();
                        return;
                    }

                    try {
                        const klass = Report.reportTypes[scope.defaultReportType];
                        params.report_type = scope.defaultReportType;
                        scope.currentReport = klass.newForUser($rootScope.currentUser, params);
                        scope._reloadDataForCurrentReport();
                    } catch (e) {
                        $location.search(REPORT_DATA_PARAM, null); // remove the invalid query param
                        $injector.get('ErrorLogService').notify(e);
                    }
                };

                scope._reloadDataForCurrentReport = () => {
                    scope._loadingReport = true;
                    scope.currentReport.save().then(response => {
                        // update maintenance status
                        scope.reportingMaintenance =
                            response.meta &&
                            response.meta.reporting_maintenance &&
                            response.meta.reporting_maintenance.bool();

                        if (scope.reportingMaintenance) {
                            scope.maintenanceReason = response.meta.reporting_maintenance_reason;
                        }

                        // report finished loading, show it
                        scope.loadingReport = false;
                        scope._loadingReport = scope.loadingReport;
                    });

                    $timeout(() => {
                        // if we're still loading report at this point (_loadingReport === false), show it in UI
                        scope.loadingReport = scope._loadingReport;
                    }, 500);
                };

                // get initial state of config flag
                ConfigFactory.getConfig().then(config => {
                    scope.reportingMaintenance = config.reporting_maintenance && config.reporting_maintenance.bool();

                    // We do not need to translate this string because it is only used
                    // in deve mode.  Also, in some cases reportingMaintenance will be passed down as text
                    // from the server.
                    if (scope.reportingMaintenance) {
                        scope.maintenanceReason = 'Maintenance mode is enabled in config.';
                    }
                });

                // initialize report based on query string
                if (angular.isUndefined(defaultReportType)) {
                    throw new Error('Must provide a defaultReportType');
                }
                scope.defaultReportType = defaultReportType;
                scope._readCurrentReportFromQueryOrStorage();
            },
        };
    },
]);
