import angularModule from 'Reports/angularModule/scripts/reports_module';
import Papa from 'papaparse';
import template from 'Reports/angularModule/views/reports_user.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { getRelevantCohorts } from 'Users';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('reportsUser', [
    '$injector',

    function factory($injector) {
        const HasCurrentReportMixin = $injector.get('HasCurrentReportMixin');
        const Stream = $injector.get('Lesson.Stream');
        const User = $injector.get('User');
        const UserIdFilter = $injector.get('UserIdFilter');
        const UserLessonProgressReport = $injector.get('UserLessonProgressReport');
        const UsersReport = $injector.get('UsersReport');
        const $location = $injector.get('$location');
        const $rootScope = $injector.get('$rootScope');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $filter = $injector.get('$filter');
        const TransientClientStorage = $injector.get('TransientClientStorage');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                availableReportTypes: '<?', // only used in tests
                gotoSection: '&',
            },
            link(scope) {
                const translationHelper = new TranslationHelper('reports.reports');

                scope.availableReportTypes = scope.availableReportTypes || ['TimeOnTaskSingleUserReport'];
                scope.UsersReport = UsersReport;
                scope.showNotStartedCourses = false;
                if (!scope.defaultReportType) {
                    scope.defaultReportType = 'TimeOnTaskSingleUserReport';
                }

                if (scope.availableReportTypes) {
                    scope.defaultReportType = scope.availableReportTypes.includes(scope.defaultReportType)
                        ? scope.defaultReportType
                        : scope.availableReportTypes[0];
                }

                // There are multiple reports on this page.  The time on task report
                // is the current one, since it has filters and can be reloaded
                HasCurrentReportMixin.onLink(scope, scope.defaultReportType, {
                    saveInQueryParam: false,
                });

                scope.reloadCallback = () => {
                    scope.reload();
                };

                scope.clearCallback = () => {
                    scope.clear();
                    const filter = scope.currentReport.addFilter(UserIdFilter);
                    const userId = scope.userId;
                    filter.value = [userId];
                    scope.reload();
                };

                scope.toggleShowNotStarted = () => {
                    scope.showNotStartedCourses = !scope.showNotStartedCourses;
                };

                scope.exportProgressCSV = () => {
                    const data = [];

                    data.push([
                        translationHelper.get('course'),
                        translationHelper.get('th_lesson'),
                        translationHelper.get('th_status'),
                        translationHelper.get(scope.userLessonProgressReport.TITLE_KEYS_FOR_COLUMN.started_at),
                        translationHelper.get(scope.userLessonProgressReport.TITLE_KEYS_FOR_COLUMN.completed_at),
                        translationHelper.get(
                            scope.userLessonProgressReport.TITLE_KEYS_FOR_COLUMN.average_assessment_score_first,
                        ),
                        translationHelper.get(
                            scope.userLessonProgressReport.TITLE_KEYS_FOR_COLUMN.average_assessment_score_best,
                        ),
                    ]);

                    _.forEach(scope.startedStreams, stream => {
                        _.forEach(stream.orderedLessons, lesson => {
                            const row = scope.userLessonProgressReport.rowForUserIdAndLocalePackId(
                                scope.userId,
                                lesson.localePackId,
                            );

                            // Compute progress status
                            let status = translationHelper.get('status_not_started');
                            if (row) {
                                if (
                                    scope.userLessonProgressReport.getTabularRowValue(row, 'started_at') &&
                                    scope.userLessonProgressReport.getTabularRowValue(row, 'completed_at')
                                ) {
                                    status = translationHelper.get('status_completed');
                                } else if (scope.userLessonProgressReport.getTabularRowValue(row, 'started_at')) {
                                    status = translationHelper.get('status_started');
                                }
                            }

                            const rowArray = [
                                stream.title,
                                lesson.title,
                                status,
                                row ? scope.userLessonProgressReport.getTabularRowValue(row, 'started_at', 'csv') : '',
                                row
                                    ? scope.userLessonProgressReport.getTabularRowValue(row, 'completed_at', 'csv')
                                    : '',
                                row && (lesson.assessment || lesson.test)
                                    ? scope.userLessonProgressReport.getTabularRowValue(
                                          row,
                                          'average_assessment_score_first',
                                          'csv',
                                      )
                                    : '',
                                row && (lesson.assessment || lesson.test)
                                    ? scope.userLessonProgressReport.getTabularRowValue(
                                          row,
                                          'average_assessment_score_best',
                                          'csv',
                                      )
                                    : '',
                            ];

                            data.push(rowArray);
                        });
                    });

                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';

                    const blob = new Blob([Papa.unparse(data)], {
                        type: 'data:text/csv;charset=utf-8',
                    });

                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = `${translationHelper.get('file_name_course_activity')}-${$filter('amDateFormat')(
                        new Date(),
                        'YYYY.MM.DD.HH.mm',
                    )}.csv`;
                    if (!window.RUNNING_IN_TEST_MODE) {
                        // headless browser does not like this -- causes a reload / error
                        a.click();
                    }
                    window.URL.revokeObjectURL(url);
                };

                function loadUserLessonProgressReport() {
                    const filter = scope.currentReport.addFilter(UserIdFilter);
                    const userId = scope.userId;
                    filter.value = [userId];
                    scope.reload();

                    const userLessonProgressReport = UserLessonProgressReport.newForUser($rootScope.currentUser);
                    const userIdFilter2 = userLessonProgressReport.addFilter(UserIdFilter);
                    userIdFilter2.value = [userId];

                    userLessonProgressReport
                        .save()
                        .then(() => User.show(scope.userId))
                        .then(response => {
                            scope.user = response.result;
                        })
                        // Note if we loaded the locale pack's here, it would be possible
                        // to display titles in the current user's locale, rather than the
                        // report user's locale.  Not sure which is preferable or if we want
                        // it to be an option
                        .then(() =>
                            Stream.index({
                                include_progress: true,
                                'fields[]': [
                                    'id',
                                    'title',
                                    'lessons',
                                    'chapters',
                                    'image',
                                    'locale_pack',
                                    'lesson_streams_progress',
                                    'time_limit_hours',
                                    'exam',
                                ],
                                'lesson_fields[]': [
                                    'id',
                                    'title',
                                    'assessment',
                                    'locale_pack',
                                    'lesson_progress',
                                    'test',
                                ],
                                filters: {
                                    cohort_ids: getRelevantCohorts(scope.user).map(cohort => cohort.id),
                                },
                                user_id: userId,
                            }),
                        )
                        .then(response => {
                            scope.streams = response.result;

                            // Build list of started and not started streams
                            scope.startedStreams = [];
                            scope.notStartedStreams = [];

                            _.forEach(scope.streams, stream => {
                                const started = _.some(stream.lessonLocalePackIds, localePackId =>
                                    userLessonProgressReport.rowForUserIdAndLocalePackId(userId, localePackId),
                                );

                                if (started) {
                                    scope.startedStreams.push(stream);
                                } else {
                                    scope.notStartedStreams.push(stream);
                                }
                            });

                            scope.userLessonProgressReport = userLessonProgressReport;
                        });
                }

                scope.$watch('userId', userId => {
                    scope.userReportData = undefined;
                    if (userId) {
                        const usersReport = UsersReport.newForUser($rootScope.currentUser);
                        const userIdFilter = usersReport.addFilter(UserIdFilter);
                        userIdFilter.value = [userId];

                        usersReport.save().then(() => {
                            const usersReportRow = usersReport.tabular_data[0];
                            scope.userReportData = {
                                userId: UsersReport.getTabularRowValue(usersReportRow, 'user_id', 'raw'),
                                userName: UsersReport.getTabularRowValue(usersReportRow, 'user_name', 'raw'),
                                email: UsersReport.getTabularRowValue(usersReportRow, 'email', 'raw'),
                                startedStreamCount: UsersReport.getTabularRowValue(
                                    usersReportRow,
                                    'started_stream_count',
                                    'raw',
                                ),
                                completedStreamCount: UsersReport.getTabularRowValue(
                                    usersReportRow,
                                    'completed_stream_count',
                                    'raw',
                                ),
                                registeredAt: UsersReport.getTabularRowValue(usersReportRow, 'registered_at', 'raw'),
                                lastLessonActivityTime: UsersReport.getTabularRowValue(
                                    usersReportRow,
                                    'last_lesson_activity_time',
                                    'raw',
                                ),
                            };

                            TransientClientStorage.setItem(scope.currentReport.report_type, userId);
                        });

                        return;
                    }
                    const queryUserId = $location.search().user_id;
                    const savedUserId = TransientClientStorage.getItem(scope.currentReport.report_type);

                    // allow querystring to take precedence, but fallback to transient store
                    scope.userId = queryUserId || savedUserId;

                    if (scope.userId) {
                        loadUserLessonProgressReport();
                    }
                });

                scope.$watchGroup(['loadingReport', 'userLessonProgressReport', 'streams', 'user'], () => {
                    scope.showContent =
                        !scope.loadingReport &&
                        scope.userLessonProgressReport &&
                        scope.userLessonProgressReport.loaded &&
                        !!scope.streams &&
                        !!scope.user;
                });
            },
        };
    },
]);
