import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('CourseFilter', [
    '$injector',
    $injector => {
        const ListFilter = $injector.get('ListFilter');

        return ListFilter.subclass(function () {
            this.alias('CourseFilter');
            this.$$placeholderKey = 'filter_placeholder_courses';
            this.availableToInstitutionalReportsViewers = true;
        });
    },
]);
