import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('TimeFilter', [
    '$injector',
    $injector => {
        const ReportFilter = $injector.get('ReportFilter');

        return ReportFilter.subclass(function () {
            this.alias('TimeFilter');

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.value = 'FIGURE THIS OUT';
            });

            this.extend({
                filterBaseType: 'time',
                options: 'FILL OUT SOME OPTIONS HERE (report date range class?)',
                dateZoom: undefined, // how to handle date zoom? Is it part of the filter?
            });
        });
    },
]);
