import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('InstitutionFilter', [
    '$injector',
    $injector => {
        const ListFilter = $injector.get('ListFilter');

        return ListFilter.subclass(function () {
            this.alias('InstitutionFilter');
            this.$$placeholderKey = 'filter_placeholder_institutions';
        });
    },
]);
