import angularModule from 'Reports/angularModule/scripts/reports_module';
import 'FrontRoyalUiBootstrap/popover';
import Papa from 'papaparse';
import template from 'Reports/angularModule/views/reports_activity.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('reportsActivity', [
    '$injector',

    function factory($injector) {
        const HasSortableColumnsMixin = $injector.get('HasSortableColumnsMixin');
        const HasCurrentReportMixin = $injector.get('HasCurrentReportMixin');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $filter = $injector.get('$filter');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                gotoSection: '&',
                availableReportTypes: '<?', // only used in tests
            },
            link(scope) {
                scope.$rootScope = $rootScope;
                const translationHelper = new TranslationHelper('reports.reports');
                HasCurrentReportMixin.onLink(scope, 'UsersReport');
                HasSortableColumnsMixin.onLink(scope);

                scope.itemsPerPage = 25;

                function setAvailableReportTypes(currentUser) {
                    scope.availableReportTypes = ['UsersReport', 'PlayerLessonSessionsReport'];

                    if (currentUser.hasSuperReportsAccess) {
                        scope.availableReportTypes.push('EditorLessonSessionsReport');
                    }
                }
                if (!scope.availableReportTypes) {
                    // Set availableReportType right away so it is accessible to other stuff.
                    setAvailableReportTypes($rootScope.currentUser);
                }

                // conditionally toggle sorting column based on report type
                scope.$watch('currentReport.report_type', () => {
                    if (scope.currentReport.report_type === 'PlayerLessonSessionsReport') {
                        scope.changeTabularSorting('started_at');
                        scope.sort.descending = true;
                    } else {
                        // default tabular sorting
                        scope.changeTabularSorting('email');
                    }
                });

                // special case handling of sorting
                scope.$watchGroup(['sort.column', 'sort.descending'], () => {
                    scope.orderingFields = scope.sort.descending ? [`-${scope.sort.column}`] : [scope.sort.column];
                    scope.orderingFields.push('email');
                });

                scope.$filter = $filter;
                scope.setLimit = limit => {
                    scope.currentReport.limit = limit;
                    scope.reload();
                };

                scope.exportCSV = () => {
                    const data = [];

                    data.push(_.map(scope.currentReport.getCsvColumns(scope.currentUser), 'csvTitle'));

                    _.forEach(scope.currentReport.tabular_data, row => {
                        data.push(
                            scope.currentReport
                                .getCsvColumns(scope.currentUser)
                                .map(column => scope.currentReport.getTabularRowValue(row, column.key, 'csv')),
                        );
                    });

                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';

                    const blob = new Blob([Papa.unparse(data)], {
                        type: 'data:text/csv;charset=utf-8',
                    });

                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = `${translationHelper.get('file_name_activity_report')}-${$filter('amDateFormat')(
                        new Date(),
                        'YYYY.MM.DD.HH.mm',
                    )}.csv`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                };

                scope.clearSearchResult = () => {
                    scope.searchValue = '';
                };
            },
        };
    },
]);
