import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('UsersReport', [
    '$injector',
    $injector => {
        const BaseTabularReport = $injector.get('BaseTabularReport');

        // eslint-disable-next-line func-names
        return BaseTabularReport.subclass(function () {
            // Note: see server report model for reference of expected fields
            this.addColumns([
                ['account_id', 'th_account_id', 'accountIdLink'],
                ['user_name', 'th_name'],
                ['registered_at', 'th_registered', 'dateWithDetails'],
            ]);

            this.addGroupedColumns('th_courses', [
                ['started_stream_count', 'th_started', 'number'],
                ['completed_stream_count', 'th_completed', 'number'],
            ]);

            this.addGroupedColumns('th_lessons', [
                ['started_lesson_count', 'th_started', 'number'],
                ['completed_lesson_count', 'th_completed', 'number'],
            ]);

            this.addColumns([['last_lesson_activity_time', 'th_last_seen', 'amTimeAgo']]);

            this.addGroupedColumns('th_assessment_group', [
                ['average_assessment_score_first', 'th_assessment_first', 'percent'],
                ['average_assessment_score_best', 'th_assessment_best', 'percent'],
            ]);

            this.addHiddenColumns(['user_id']);
            // FIXME: we also could get started_lesson_locale_pack_ids, etc.   Could show the list in a rollover or something if we wanted

            // must set _alias so reports_overview_dir can setup the selectize
            this._alias = this.alias('UsersReport');
            this.$$titleKey = 'report_title_users';

            this.addFilterKlasses([$injector.get('CourseStartedFilter'), $injector.get('CourseCompletedFilter')], {
                after: $injector.get('EmailNameFilter'),
            });

            this.availableGroupByIdentifiers = [];

            // TODO: add multiple time filters (registered_at, last_seen), and ensure last_seen is the "primary" filter
            // TODO: add configuration to enable "Show all users" in last_seen time filter (basically remove filter) and make it default option
        });
    },
]);
