import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('ClientTypeFilter', [
    '$injector',
    $injector => {
        const ListFilter = $injector.get('ListFilter');

        return ListFilter.subclass(function () {
            this.alias('ClientTypeFilter');
            this.$$placeholderKey = 'filter_placeholder_client_types';
        });
    },
]);
