import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('SignUpCodeFilter', [
    '$injector',
    $injector => {
        const ListFilter = $injector.get('ListFilter');

        return ListFilter.subclass(function () {
            this.alias('SignUpCodeFilter');
            this.$$placeholderKey = 'filter_placeholder_sign_up_codes';
        });
    },
]);
