import angularModule from 'Reports/angularModule/scripts/reports_module';
import template from 'Reports/angularModule/views/reports_filter_bar.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('reportsFilterBar', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const TranslationHelper = $injector.get('TranslationHelper');
        const OrderedHash = $injector.get('OrderedHash');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                availableReportTypes: '<', // list of available report type aliases, e.g.: ['ActiveUsersReport']
                currentReport: '=', // needs two-way binding because this can be set here when the type changes
                reloadCallback: '&',
                clearCallback: '&',
            },
            link(scope) {
                const Report = (scope.Report = $injector.get('Report'));
                scope.ListFilter = $injector.get('ListFilter');

                const translationHelper = new TranslationHelper('reports.reports');
                scope.selectedReportPlaceholder = translationHelper.get('report_title_placeholder');
                scope.comparePlaceholder = translationHelper.get('filter_placeholder_compare');

                scope.$watch('availableReportTypes', availableReportTypes => {
                    if (!availableReportTypes) {
                        return;
                    }

                    // order the reports based on the order defined in
                    // Report.reportTypes
                    const orderedReports = _.chain(availableReportTypes)
                        .sortBy(key => {
                            const index = Report.orderedReportNames.indexOf(key);
                            if (index === -1) {
                                throw new Error(`${key} is not in Report.reportTypes.`);
                            }
                            return index;
                        })
                        .map(key => [key, Report.reportTypes[key]])
                        .value();
                    scope.availableReports = OrderedHash.create(orderedReports);
                });

                scope.$watch('availableReports', availableReports => {
                    if (!availableReports) {
                        scope.selectedReportTypeAlias = undefined;
                        return;
                    }

                    // use the currentReport as the default selected type, else default to first entry in the availableReports list
                    scope.selectedReportTypeAlias = scope.selectedReportTypeAlias || scope.availableReports[0].alias();
                });

                // create a getter/setter to bind to the report type selectize
                Object.defineProperty(scope, 'selectedReportTypeAlias', {
                    get() {
                        return scope.currentReport && scope.currentReport.constructor.alias();
                    },
                    set(alias) {
                        const klass = scope.availableReports[alias];
                        if (scope.currentReport && scope.currentReport.constructor === klass) {
                            return alias;
                        }
                        if (!klass) {
                            throw new Error(`No report type found for "${alias}"`);
                        }

                        scope.currentReport = klass.newForUser($rootScope.currentUser);
                        return alias;
                    },
                });

                // NOTE: we may need a cleaner way of handling reportTypes.title / $$titleKey
                // synchronization in conjunction with locale-switching
                scope.Report.reportTypes.forEach(reportType => {
                    reportType.title = translationHelper.get(reportType.$$titleKey);
                });

                scope.reload = event => {
                    if (event) {
                        event.preventDefault();
                    }

                    scope.reloadCallback();
                };

                scope.clear = () => {
                    scope.clearCallback();
                };

                scope.$watchGroup(['currentReport', '$rootScope'], () => {
                    if (scope.currentReport) {
                        scope.currentReport.ensureFilterOptions($rootScope.currentUser);
                        scope.FilterKlasses = _.filter(scope.currentReport.availableFilterKlasses, FilterKlass => {
                            if (FilterKlass.$$labelKey) {
                                FilterKlass.label = translationHelper.get(FilterKlass.$$labelKey);
                            }

                            if (FilterKlass.$$placeholderKey) {
                                FilterKlass.placeholder = translationHelper.get(FilterKlass.$$placeholderKey);
                            }

                            return FilterKlass.availableForUser($rootScope.currentUser);
                        });

                        scope.availableGroupBys = scope.currentReport.availableGroupBysForUser($rootScope.currentUser);
                    }
                });
            },
        };
    },
]);
