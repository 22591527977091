import angularModule from 'Reports/angularModule/scripts/reports_module';
import moment from 'moment-timezone';

/*

    ==== Report

    A Report is a record with the following properties:

        * report_type (string):
            indicates which subclass of Report to use,
            for example, ActiveUsers

        * date_range (ReportDateRange):
            object describing the range of dates this report should apply to

        * date_zoom_id (string):
            one of day/week/month.  Indicates whether time
            series data is aggregated daily, weekly, or monthly

        * group_bys (array of strings):
            list of column names to use as a group by in the
            query.  For example, if you want one line for each
            sign-up code, this could be ['sign_up_code']

        * filters (array of ReportFilter instances):
            defines how the report should be filtered

    === report_type

    The report_type (subclass of Report) that is used will define
    a certain things on the client and certain things on the server.

    On the client, the report_type defines

        * the default title for instances of this report
        * the filter types (i.e. SignUpCodeFilter) that can be applied
            to instances of this report
        * the group_bys that can be applied to this report

    On the server, the report_type defines

        * which table to pull this report's data from
        * which column to use as the source for time info
        * which column to use as the thing to count distinct
            instances of (for example, user_id)

    === Filters

    The filters applied to an instance of a report are themselves
    instances of a subclass of ReportFilter.  For example, SignUpCodeFilter
    is a subclass of ListFilter, which is a subclass of ReportFilter.

    ListFilters are filters in which the class defines a column and a list
    of possible values.  In any instance of a list filter, zero or more
    of the possible values will be selected.  For example, SignUpCodeFilter
    defines the column as "sign_up_code" and the possible values as all
    existing values of sign_up_code.

    TextFilters are filters in which the class defines one or more columns
    and a value to match against the columns. A match in any of the columns
    indicates that record should be returned. For example, EmailNameFilter
    defines the columns "email" and "name" to allow
    case-insensitive matching.

*/
angularModule.factory('Report', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const OrderedHash = $injector.get('OrderedHash');
        const $http = $injector.get('$http');
        const ReportDateRange = $injector.get('ReportDateRange');
        const TranslationHelper = $injector.get('TranslationHelper');

        // eslint-disable-next-line func-names
        return Iguana.subclass(function () {
            const Report = this;

            const translationHelper = new TranslationHelper('reports.reports');

            this.setCollection('reports');
            this.alias('Report');
            this.setSciProperty('report_type');

            this.embedsMany('filters', 'ReportFilter');
            this.embedsOne('date_range', 'ReportDateRange');

            this.defineSetter('date_zoom_id', function dateZoomIdSetter(val) {
                this.writeKey('date_zoom_id', val);
                if (this.dateRange) {
                    this.dateRange.unit = val;
                }
            });

            // make this a getter to prevent recursive dependencies
            Object.defineProperty(this, 'reportTypes', {
                get() {
                    this.$$reportTypes =
                        this.$$reportTypes ||
                        OrderedHash.create([
                            ['ActiveUsersReport', $injector.get('ActiveUsersReport')],
                            ['UsersReport', $injector.get('UsersReport')],
                            ['TimeOnTaskReport', $injector.get('TimeOnTaskReport')],
                            ['TimeOnTaskSingleUserReport', $injector.get('TimeOnTaskSingleUserReport')],
                            ['UserLessonProgressReport', $injector.get('UserLessonProgressReport')],
                            ['PlayerLessonSessionsReport', $injector.get('PlayerLessonSessionsReport')],
                            ['EditorLessonSessionsReport', $injector.get('EditorLessonSessionsReport')],
                        ]);
                    return this.$$reportTypes;
                },
            });

            Object.defineProperty(this, 'orderedReportNames', {
                get() {
                    this.$$orderedReportNames = this.$$orderedReportNames || _.invokeMap(Report.reportTypes, 'alias');
                    return this.$$orderedReportNames;
                },
            });

            Object.defineProperty(this.prototype, 'dateZoom', {
                get() {
                    return this.availableDateZooms[this.date_zoom_id];
                },
                set(val) {
                    this.date_zoom_id = val.id;
                },
            });

            Object.defineProperty(this.prototype, 'dateRange', {
                get() {
                    return this.date_range;
                },
                set(val) {
                    this.date_range = val;
                },
            });

            Object.defineProperty(this.prototype, 'groupBys', {
                get() {
                    return this.group_bys;
                },
                set(val) {
                    this.group_bys = val;
                },
            });

            Object.defineProperty(this.prototype, 'loaded', {
                get() {
                    return angular.isDefined(this.time_series_data) || angular.isDefined(this.tabular_data);
                },
            });

            Object.defineProperty(this.prototype, 'plotlyTimeSeriesChartData', {
                get() {
                    if (
                        !this.$$plotlyTimeSeriesChartData ||
                        this.$$plotlyTimeSeriesChartData.sourceData !== this.time_series_data
                    ) {
                        this.$$plotlyTimeSeriesChartData = this._getDataForPlotlyLineChart();
                    }
                    return this.$$plotlyTimeSeriesChartData;
                },
            });

            Object.defineProperty(this.prototype, 'startTime', {
                get() {
                    return this.date_range.startTime;
                },
            });

            Object.defineProperty(this.prototype, 'finishTime', {
                get() {
                    return this.date_range.finishTime;
                },
            });

            // delegate certain properties to the class
            [
                'availableFilterKlasses',
                'availableDateZooms',
                'availableGroupBys',
                'dateZoomEnabled',
                'unlimitedRangeEnabled',
            ].forEach(prop => {
                Object.defineProperty(Report.prototype, prop, {
                    get() {
                        return this.constructor[prop];
                    },
                    configurable: true, // useful for specs
                });
            });

            this.GROUP_BY_KEYS = {
                sign_up_code: 'filter_group_by_sign_up_code ',
                institution_name: 'filter_group_by_institution',
                role_name: 'filter_group_by_role ',
                group_name: 'filter_group_by_group',
            };

            this.extend({
                newForUser(user, params = {}) {
                    const report = this.new(params);
                    report.setDefaultFiltersForUser(user);
                    return report;
                },

                // these ids (day, week, month) are read by moment.js below, using startOf,
                // and on the server side when preparing the query
                availableDateZooms: OrderedHash.create([
                    [
                        'day',
                        {
                            id: 'day',
                            $$titleKey: 'date_range_zoom_daily',
                            unit: 'day',
                        },
                    ],
                    [
                        'week',
                        {
                            id: 'week',
                            $$titleKey: 'date_range_zoom_weekly',
                            unit: 'isoWeek', // isoWeek starts on Monday.  This is what postgres uses, so this is what we use here
                        },
                    ],
                    [
                        'month',
                        {
                            id: 'month',
                            $$titleKey: 'date_range_zoom_monthly',
                            unit: 'month',
                        },
                    ],
                ]),

                dateZoomEnabled: true,

                unlimitedRangeEnabled: false,

                getFilterOptions(filterType, user) {
                    const self = this;

                    // If haven't loaded options already, or if we
                    // haven't loaded options for this user, then
                    // we need to make an api call.
                    if (
                        !self.$$filterOptionsPromise ||
                        self.$$filterOptionsPromise.$$userId !== user.id ||
                        self.$$filterOptionsPromise.$$localeId !== user.pref_locale
                    ) {
                        const params = {
                            report_type: this.alias(),
                        };

                        // if a user does not have super reports access, then they
                        // must be a institutional reports viewer and must pass along
                        // an institution_id
                        if (!user.hasSuperReportsAccess && user.institutions[0]) {
                            params.institution_id = user.institutions[0].id;
                        }
                        this.$$filterOptionsPromise = $http.get(
                            `${window.ENDPOINT_ROOT}/api/reports/filter_options.json`,
                            {
                                params,
                            },
                        );
                        this.$$filterOptionsPromise.$$userId = user.id;
                        this.$$filterOptionsPromise.$$localeId = user.pref_locale;
                    }

                    return this.$$filterOptionsPromise.then(response => {
                        let results;
                        try {
                            results = response.data.contents.filter_options;
                        } catch (e) {
                            throw new Error(`No options found in response: ${JSON.stringify(response)}`);
                        }
                        const record = _.find(results, r => r.filter_type === filterType);

                        return record ? record.options : [];
                    });
                },

                availableGroupByIdentifiers: [],
                availableFilterKlasses: [],
            });

            // FIXME: https://trello.com/c/6VGDipLc/903-chore-move-color-definitions-in-report-to-oreo-module
            const lineColors = [
                '149,87,236', // purple
                '255,77,99', // coral
                '29,208,180', // turquoise
                '255,190,0', // yellow
                '77,122,255', // blue
                '255,99,87', // orange
                '13,208,55', // green
                '231,56,77', // red,
                '143,129,125', // grey
                '96,4,72', // plum
            ];

            const barColors = [
                '77,122,255', // blue
                '255,190,0', // yellow
                '29,208,180', // turquoise
                '255,99,87', // orange
                '13,208,55', // green
                '149,87,236', // purple
                '255,77,99', // coral
                '143,129,125', // grey
                '231,56,77', // red
                '96,4,72', // plum
            ];

            this.PLOTLY_LINE_CONFIGS = lineColors.map(color => ({
                color: `rgba(${color},1)`,
            }));
            this.PLOTLY_BAR_CONFIGS = barColors.map(color => ({
                color: `rgba(${color},1)`,
            }));
            const plotlyLineConfigs = this.PLOTLY_LINE_CONFIGS;

            const plotlyBarConfigs = this.PLOTLY_BAR_CONFIGS;

            this.setCallback('after', 'copyAttrsOnInitialize', function afterCopyAttrsOnInitialize() {
                this.groupBys = this.groupBys || [];
                this.filters = this.filters || [];
                this.dateZoom = this.dateZoom || this.availableDateZooms[0];

                if (!this.date_range) {
                    this.date_range = this.getDefaultDateRange();
                }

                this.availableDateZooms.forEach(dateZoom => {
                    dateZoom.title = translationHelper.get(dateZoom.$$titleKey);
                });

                // these only apply to TabularReports
                this.fields = this.constructor.DEFAULT_FIELDS;
                this.limit = 2000;

                const currentReportType = Report.reportTypes[this.report_type];
                if (!currentReportType) {
                    $injector.get('ErrorLogService').notify(`No report type found for ${this.report_type}`);
                    return;
                }

                // we might not have $$titleKey in tests, which is probably fine  =]
                this.title = currentReportType.$$titleKey
                    ? translationHelper.get(Report.reportTypes[this.report_type].$$titleKey)
                    : this.constructor.title;
            });

            return {
                getDefaultDateRange() {
                    if (this.unlimitedRangeEnabled) {
                        return ReportDateRange.new({
                            type: 'all',
                        });
                    }
                    return ReportDateRange.new({
                        unit: this.date_zoom_id,
                    });
                },

                ensureFilterOptions(user) {
                    // For any ListFilter, call ensureOptions
                    const alias = this.constructor.alias();
                    _.chain(this.availableFilterKlasses)
                        .forEach(FilterKlass => {
                            if (FilterKlass.ensureOptions) {
                                FilterKlass.ensureOptions(alias, user);
                            }
                        })
                        .value();
                },

                availableGroupBysForUser(user) {
                    if (!user.hasSuperReportsAccess) {
                        return [];
                    }

                    return _.chain(this.constructor.availableGroupByIdentifiers)
                        .map(identifier => ({
                            identifier,
                            title: translationHelper.get(Report.GROUP_BY_KEYS[identifier]),
                        }))
                        .value();
                },
                addFilter(FilterKlass) {
                    const filter = FilterKlass.new();
                    this.filters.push(filter);
                    return filter;
                },

                removeFilter(filter) {
                    this.filters = _.without(this.filters, filter);
                },

                /*
                    Given the list from Report.availableFilterKlasses,
                    we want to generate a bunch of selectize elements with
                    their ng-models bound to a filter instance.  When some
                    values are selected, we want to ensure a filter instance
                    exists in the report.  When no value is selected, we want
                    to ensure that there is no filter instance in the report.

                    This method returns a function which is a getter/setter that
                    the ng-model can use to implement the above requirements.
                */
                getterSetterForFilter(FilterKlass) {
                    const name = FilterKlass.alias();
                    const self = this;
                    this.$$filterGetterSetters = this.$$filterGetterSetters || {};

                    if (!this.$$filterGetterSetters[name]) {
                        this.$$filterGetterSetters[name] = function getOrSetFilter(val) {
                            let filter = _.find(self.filters, f => f.constructor === FilterKlass);

                            // Getter
                            if (arguments.length === 0) {
                                return filter?.value;
                            }

                            // Setter
                            // _.some will work on arrays, strings, and undefined.  Might not
                            // work on everything we ever want to pass in here, depending on
                            // what future filter types we have.
                            if (_.some(val)) {
                                filter = filter || self.addFilter(FilterKlass);
                                filter.value = val;
                            } else if (filter) {
                                self.removeFilter(filter);
                            }

                            // For setter, return the filter or null if it was removed
                            return filter || null;
                        };
                    }

                    return this.$$filterGetterSetters[name];
                },

                setDefaultFiltersForUser(user) {
                    if (!user.hasSuperReportsAccess) {
                        const filter = this.addFilter($injector.get('InstitutionFilter'));
                        filter.value = [user.institutions[0].id];
                    }
                },

                asJson($super) {
                    return _.omit($super(), 'time_series_data', 'tabular_data');
                },

                toJson() {
                    return angular.toJson(this.asJson());
                },

                _getDataForPlotlyLineChart() {
                    const self = this;
                    const lineChartData = this._getLineChartSeries();

                    if (!lineChartData) {
                        return undefined;
                    }

                    const xDates = _.invokeMap(lineChartData.dates, 'toDate');
                    const plotlyData = lineChartData.series.map((chartLine, i) => {
                        const props = {
                            x: xDates,
                            y: chartLine.data,
                            name: chartLine.label,
                        };

                        if (self.chartType === 'bar') {
                            const barConfig = plotlyBarConfigs[i % plotlyBarConfigs.length];
                            angular.extend(props, {
                                type: 'bar',
                                marker: barConfig,
                            });
                        } else {
                            const lineConfig = plotlyLineConfigs[i % plotlyLineConfigs.length];
                            angular.extend(props, {
                                type: 'scatter', // plotly line charts are called 'scatter' see https://plot.ly/javascript/reference/#scatter
                                line: lineConfig,
                            });
                        }

                        return props;
                    });

                    // store this so we know when the data has changed and
                    // needs to be refreshed
                    plotlyData.sourceData = this.time_series_data;

                    return plotlyData;
                },

                /*
                    This is broken out into a separate method from
                    _getDataForPlotlyLineChart to kind of try to
                    separate the process of converting the data to
                    generic line cart data with converting it precisely
                    to how plotlyJs does it.  Having it structured this
                    way helped with the conversion from ChartJs to plotly
                    and maybe would help if we decided to convert to
                    something else in the future.

                    returns something like:
                    {
                        dates: [dateObj1, dateObj2 ...],
                        series: [
                            {
                                label: 'Series 1',
                                data: [42, 73, 12, ...]
                            },
                            {
                                label: 'Series 2',
                                data: [1, 98, 34, ...]
                            }
                        ]
                    }
                */
                _getLineChartSeries() {
                    const data = this.time_series_data;

                    if (!data || _.size(data) === 0) {
                        return undefined;
                    }
                    const self = this;
                    // map of timestamps to indexes, so we can fill
                    // in the empty spaces in the data
                    const dateMap = {};
                    let datesLength = 0;
                    const dates = [];

                    // since the database uses UTC, all of the data is assigned
                    // to days according to UTC time.  This is hard to change in
                    // the db, at least for weeks, because date_trunc does not support
                    // anything other than utc (or maybe anything other than the db's
                    // timezone, which is practically the same)
                    let utcDay = moment(this.startTime).utc().startOf(this.dateZoom.unit);
                    while (utcDay.toDate() <= this.finishTime) {
                        dateMap[utcDay.valueOf()] = datesLength;
                        datesLength += 1;

                        const dateStr = utcDay.format('YYYY-MM-DD');
                        const localDay = moment(dateStr);
                        dates.push(localDay);
                        utcDay = utcDay.add(1, this.dateZoom.id);
                    }

                    const series = {};

                    _.forEach(data, (val, key) => {
                        const keyParts = JSON.parse(key);
                        let seriesLabel;
                        let dateStr;
                        if (Array.isArray(keyParts)) {
                            dateStr = keyParts.pop();
                            seriesLabel = keyParts.length > 0 ? keyParts.join(' / ') : '_';
                        } else {
                            dateStr = keyParts;
                            seriesLabel = self.title;
                        }

                        const timestamp = 1000 * Number(dateStr);
                        let chartLine = series[seriesLabel];
                        if (!chartLine) {
                            chartLine = {
                                label: seriesLabel,
                                data: [],
                            };

                            // initialize all values as 0, since some may not
                            // be defined in the data
                            for (let i = 0; i < datesLength; i++) {
                                chartLine.data[i] = 0;
                            }

                            series[seriesLabel] = chartLine;
                        }

                        const dataPointIndex = dateMap[timestamp];
                        chartLine.data[dataPointIndex] = val;
                    });

                    return {
                        dates,
                        series: _.chain(series).values().sortBy('label').value(),
                    };
                },

                formatDate(date) {
                    if (this.dateZoom.id === 'month') {
                        return date.format('MMM');
                    }
                    return date.format('MMM D');
                },
            };
        });
    },
]);
