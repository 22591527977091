import angularModule from 'Reports/angularModule/scripts/reports_module';
import template from 'Reports/angularModule/views/reports_maintenance_mode_message.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('reportsMaintenanceModeMessage', [
    '$injector',

    function factory($injector) {
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                reportingMaintenance: '=',
                loadingReport: '=',
                maintenanceReason: '=',
            },
            link(scope) {
                ConfigFactory.getConfig().then(config => {
                    if (config.appEnvType() === 'development') {
                        scope.showMaintenanceReason = true;
                    }
                });
            },
        };
    },
]);
