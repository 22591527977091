import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('EmailNameFilter', [
    '$injector',
    $injector => {
        const ListFilter = $injector.get('TextFilter');

        return ListFilter.subclass(function () {
            this.alias('EmailNameFilter');
            this.$$labelKey = 'filter_label_username_or_name';
            this.availableToInstitutionalReportsViewers = true;
        });
    },
]);
