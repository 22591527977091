
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.reports = window.Smartly.locales.modules.en.back_royal.reports || {};
window.Smartly.locales.modules.en.back_royal.reports.reports = window.Smartly.locales.modules.en.back_royal.reports.reports || {};
window.Smartly.locales.modules.en.back_royal.reports.reports = {...window.Smartly.locales.modules.en.back_royal.reports.reports, ...{
    "activity": "Activity Report",
    "apply": "Apply",
    "clear": "Clear",
    "completed": "COMPLETED",
    "completed_subtext": "Completed",
    "course": "Course",
    "course_activity": "COURSE ACTIVITY",
    "date_range_zoom_daily": "Daily",
    "date_range_zoom_monthly": "Monthly",
    "date_range_zoom_weekly": "Weekly",
    "date_range_interval_between": "Between",
    "date_range_interval_last": "Last",
    "date_range_interval_since": "Since",
    "date_range_label_show_all": "Show all",
    "date_range_label_between": "Between {{start}} and {{end}}",
    "date_range_label_last_day_plural": "Last {{value}} days",
    "date_range_label_last_day_singular": "Last {{value}} day",
    "date_range_label_last_week_plural": "Last {{value}} weeks",
    "date_range_label_last_week_singular": "Last {{value}} week",
    "date_range_label_last_month_plural": "Last {{value}} months",
    "date_range_label_last_month_singular": "Last {{value}} month",
    "date_range_label_since": "Since {{start}}",
    "date_range_unit_days": "days",
    "date_range_unit_months": "months",
    "date_range_unit_weeks": "weeks",
    "export_as_csv": "Export as CSV",
    "file_name_activity_report": "users_activity",
    "file_name_course_activity": "user_courses_activity",
    "filter_empty_courses": "(No Courses)",
    "filter_empty_courses_completed": "(No Courses Completed)",
    "filter_empty_courses_started": "(No Courses Started)",
    "filter_empty_groups": "(No Groups)",
    "filter_empty_institutions": "(No Institution)",
    "filter_empty_roles": "(No Role)",
    "filter_empty_sign_up_codes": "(No Sign Up Code)",
    "filter_empty_unknown": "(None)",
    "filter_group_by_group": "Group",
    "filter_group_by_institution": "Institution",
    "filter_group_by_role": "Role",
    "filter_group_by_sign_up_code": "Sign Up Code",
    "filter_label_cohorts": "Cohorts",
    "filter_label_compare": "Compare",
    "filter_label_courses": "Courses",
    "filter_label_courses_completed": "Courses Completed",
    "filter_label_courses_started": "Courses Started",
    "filter_label_username_or_name": "Username or name",
    "filter_label_groups": "Groups",
    "filter_label_institutions": "Institutions",
    "filter_label_roles": "Roles",
    "filter_label_sign_up_codes": "Sign Up Codes",
    "filter_placeholder_cohorts": "Cohorts (all)",
    "filter_placeholder_client_types": "Client (all)",
    "filter_placeholder_compare": "Compare (none)",
    "filter_placeholder_courses": "Courses (all)",
    "filter_placeholder_lessons": "Lessons (all)",
    "filter_placeholder_locale": "Locale (all)",
    "filter_placeholder_courses_completed": "Courses Completed (all)",
    "filter_placeholder_courses_started": "Courses Started (all)",
    "filter_placeholder_text": "contains...",
    "filter_placeholder_groups": "Group (all)",
    "filter_placeholder_institutions": "Institution (all)",
    "filter_placeholder_roles": "Role (all)",
    "filter_placeholder_sign_up_codes": "Sign Up Code (all)",
    "filter_placeholder_registered": "Registered?",
    "in_progress": "In Progress",
    "last_seen": "Last seen",
    "last_seen_x": "Last seen {{when}}",
    "maintenance_mode_message": "<h2>Reports are currently undergoing routine maintenance. <br> Please try again shortly! <br> <button ng-click=\"reload()\" class=\"flat coral hollow apply\">Refresh</button></h2>",
    "mark_as_not_started": "MARK AS NOT STARTED",
    "lock": "LOCK",
    "unlock": "UNLOCK",
    "lock_course_confirm": "This will lock the course so the user cannot open it. Proceed?",
    "unlock_course_confirm": "This will unlock the course so the user can open it. Proceed?",
    "reset_timer_time_left": "RESET TIMER ({{msLeft | amDurationFormat}} left)",
    "reset_timer_time_expired": "RESET TIMER (No time left)",
    "reset_timer_timer_not_started": "TIMER NOT STARTED",
    "no_report_loaded": "<h2>Select filter options above. <br> Click <button ng-click=\"reload()\" class=\"flat coral hollow apply\">Refresh</button> to load data.</h2>",
    "no_results_found": "<h2>No results found! <br> Adjust filters or click <button ng-click=\"clear()\" class=\"flat beigebeyonddark hollow clear\">Clear</button> to start over.</h2>",
    "no_results_found_short": "<h2>No results found!</h2>",
    "no_user_selected": "No User Selected",
    "overview": "Overview",
    "pagination_limit": "(limit: {{limit}})",
    "pagination_results_found": "{{num}} results found",
    "quick_search_placeholder": "Filter results...",
    "quick_search_reset": "Reset Filter",
    "refresh": "Refresh",
    "registered_x": "Registered {{date}}",
    "report_title_placeholder": "Report",
    "report_title_active_users": "Active Users",
    "report_title_users": "Users",
    "report_title_player_lesson_sessions": "Lesson Sessions",
    "report_title_editor_lesson_sessions": "Editor Sessions",
    "report_title_time_on_task": "Time on Task",
    "reports_title": "REPORTS",
    "report_y_axis_time_on_task_min": "Time on Task (min)",                                                                    
    "reset_course_confirm": "This will reset the course to appear as not started to the learner. Proceed?",
    "reset_timer_confirm": "This will reset the timer on the exam so the user can open it again. Proceed?",
    "reset_at": "Reset",
    "show_not_started": "SHOW NOT STARTED COURSES",
    "started": "Started",
    "th_email": "Email",
    "th_name": "Name",
    "th_registered": "Registered",
    "th_account_id": "Username",
    "th_client_type": "Client",
    "th_courses": "Courses",
    "th_course": "Course",
    "th_lesson": "Lesson",
    "th_lessons": "Lessons",
    "th_courses_started": "Courses Started",
    "th_courses_completed": "Courses Completed",
    "th_lessons_started": "Lessons Started",
    "th_lessons_completed": "Lessons Completed",
    "th_status": "Status",
    "th_started": "Started",
    "th_completed": "Completed",
    "th_total_time": "Total Time",
    "th_assessment": "Assessment Scores (Avg)",
    "th_assess_score": "Assessment Score",
    "th_assessment_group": "Assessment",
    "th_assessment_first": "First",
    "th_assessment_best": "Best",
    "th_assessment_score_first": "First Score",
    "th_assessment_score_best": "Best Score",
    "th_assessment_attempts_total": "# Completions",
    "th_last_seen": "Last Seen",
    "th_started_in_this_session": "First Session",
    "th_completed_in_this_session": "Completed",
    "th_save_count": "Saves",
    "total_time": "Total Time",
    "to_use_user_report": "To use this report, search for users in the <strong>Activity Report</strong>, then click a user's email to view their details here.",
    "user": "User Detail",
    "view_activity_report": "VIEW ACTIVITY REPORT",
    "x_available_courses": "{{num}} available courses",
    "x_completed": "{{num}} completed",
    "x_in_progress": "{{num}} in progress",
    "status_started": "Started",
    "status_not_started": "Not Started",
    "status_completed": "Completed",
    "authenticate": "AUTHENTICATE",
    "authenticate_tooltip": "Administratively override proctoring verification for this exam",
    "manually_authenticated": "MANUALLY AUTHENTICATED",
    "manually_authenticate_stream_confirm": "This will allow the user to bypass BioSig-ID verification for this exam. Use when you have verified their photo ID. Proceed?"
}
}
    