import angularModule from 'Reports/angularModule/scripts/reports_module';
import template from 'Reports/angularModule/views/reports.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('reports', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const $location = $injector.get('$location');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            restrict: 'E',
            scope: {
                section: '@', // see also: availableSections
            },
            templateUrl,

            link(scope) {
                const REPORT_PARAM = 'report';

                scope.REPORTS_FORM_CLASSES = $injector.get('REPORTS_FORM_CLASSES');

                const allSections = ['overview', 'activity', 'user'];

                //-------------------------
                // Navigation / Actions
                //-------------------------

                NavigationHelperMixin.onLink(scope);

                // List of all sections available for navigation
                scope.$watch('currentUser', () => {
                    // For now, all users get all sections, but in the future we might want to adjust
                    // what is available based on the user, e.g.: institutional users vs. admins
                    scope.availableSections = allSections;
                });

                scope.gotoSection = (section, userId) => {
                    if (userId) {
                        $location.search('user_id', userId);
                        $location.search('report_data', null);
                    } else {
                        $location.search('user_id', null);
                    }

                    scope.section = section;
                    $location.search(REPORT_PARAM, section);
                };

                //-------------------------
                // Display Helpers
                //-------------------------

                scope.getNavClasses = section => (scope.section === section ? 'selected' : '');

                //-------------------------
                // Setup
                //-------------------------

                if ($location.search()[REPORT_PARAM]) {
                    scope.section = $location.search()[REPORT_PARAM];
                }

                // default to overview
                if (!scope.section || !allSections.includes(scope.section)) {
                    scope.gotoSection('overview');
                }

                // Setup localization keys
                const translationHelper = new TranslationHelper('reports.reports');

                AppHeaderViewModel.setTitleHTML(translationHelper.get('reports_title'));

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });
            },
        };
    },
]);
