import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('TimeOnTaskReport', [
    '$injector',
    $injector => {
        const Report = $injector.get('Report');

        return Report.subclass(function () {
            // must set _alias so reports_overview_dir can setup the selectize
            this._alias = this.alias('TimeOnTaskReport');
            this.$$titleKey = 'report_y_axis_time_on_task_min';

            this.availableFilterKlasses = [
                $injector.get('EmailNameFilter'),
                $injector.get('SignUpCodeFilter'),
                $injector.get('InstitutionFilter'),
                $injector.get('CohortFilter'),
                $injector.get('RoleFilter'),
                $injector.get('GroupFilter'),
            ];

            this.availableGroupByIdentifiers = ['sign_up_code', 'institution_name', 'role_name', 'group_name'];
        });
    },
]);
