import angularModule from 'Reports/angularModule/scripts/reports_module';
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown';
import 'angular-bootstrap-datetimepicker';
import template from 'Reports/angularModule/views/report_date_range_input.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import reportsCalendar from 'vectors/reports_calendar.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('reportDateRangeInput', [
    '$injector',

    function factory($injector) {
        const ReportDateRange = $injector.get('ReportDateRange');
        const TranslationHelper = $injector.get('TranslationHelper');
        const safeDigest = $injector.get('safeDigest');

        return {
            restrict: 'E',
            templateUrl,
            require: 'ngModel', // get a hold of NgModelController
            scope: {
                unlimitedRangeEnabled: '<',
            },
            link(scope, elem, attrs, ngModelCtrl) {
                scope.reportsCalendar = reportsCalendar;

                const translationHelper = new TranslationHelper('reports.reports');

                const currentEl = elem.find('.current');
                const body = $('body');

                Object.defineProperty(scope, 'dateRange', {
                    get() {
                        return ngModelCtrl.$modelValue;
                    },
                });

                scope.typeOptions = [
                    {
                        value: 'last',
                        text: translationHelper.get('date_range_interval_last'),
                    },
                    {
                        value: 'since',
                        text: translationHelper.get('date_range_interval_since'),
                    },
                    {
                        value: 'between',
                        text: translationHelper.get('date_range_interval_between'),
                    },
                ];

                scope.$watch('unlimitedRangeEnabled', unlimitedRangeEnabled => {
                    // do nothing if it's undefined
                    if (angular.isUndefined(unlimitedRangeEnabled)) {
                        return;
                    }

                    // if range is enabled, add it to the list of typeOptions
                    if (unlimitedRangeEnabled && scope.typeOptions.length !== 4) {
                        scope.typeOptions.unshift({
                            value: 'all',
                            text: translationHelper.get('date_range_label_show_all'),
                        });

                        // if unlimited range is not enabled ensure it's not the selected option
                    } else if (!unlimitedRangeEnabled && scope.dateRange && scope.dateRange.type === 'all') {
                        scope.dateRange.type = 'last';
                    }
                });

                scope.applyRange = () => {
                    ngModelCtrl.$setViewValue(scope.dateRangeClone);
                    ngModelCtrl.$render();
                    scope.dateRangeClone = undefined;
                    scope.focused = false;
                };

                scope.$watch('focused', val => {
                    if (val) {
                        const asJson = scope.dateRange.asJson();
                        scope.dateRangeClone = ReportDateRange.new(asJson);
                    }
                });

                scope.focused = false;

                scope.spyOnMe = () => {};

                body.on(`click.${scope.$id}`, event => {
                    scope.spyOnMe(); // tests
                    let clickTarget;
                    if ($(event.target).closest(currentEl).length > 0) {
                        clickTarget = 'selectizeInput';
                    } else if ($(event.target).closest(elem).length > 0) {
                        clickTarget = 'inside';
                    } else {
                        clickTarget = 'outside';
                    }

                    if (clickTarget === 'selectizeInput') {
                        scope.focused = !scope.focused;
                        safeDigest(scope);
                    } else if (clickTarget === 'outside' && scope.focused) {
                        scope.focused = false;
                        safeDigest(scope);
                    }
                });

                scope.$on('$destroy', () => {
                    body.off(`click.${scope.$id}`);
                });

                const datetimepickerConfigs = {};
                scope.datetimepickerConfig = dropdownSelector => {
                    datetimepickerConfigs[dropdownSelector] = datetimepickerConfigs[dropdownSelector] || {
                        dropdownSelector,
                        minView: 'day',
                    };
                    return datetimepickerConfigs[dropdownSelector];
                };
            },
        };
    },
]);
