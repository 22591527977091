import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('TextFilter', [
    '$injector',
    $injector => {
        const ReportFilter = $injector.get('ReportFilter');

        return ReportFilter.subclass(function () {
            this.alias('TextFilter');

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.value = this.value || undefined;
            });

            this.extend({
                filterBaseType: 'text',
                label: 'FIXME OVERRIDE LABEL', // you should really override this in child classes
                $$placeholderKey: 'filter_placeholder_text', // 'text to match' // optional to override in child classes
            });
        });
    },
]);
