import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.directive('plotly', [
    '$injector',

    function factory($injector) {
        const Plotly = $injector.get('$window').Plotly;
        const Locale = $injector.get('Locale');

        return {
            restrict: 'E',
            template: '<div class="plotly"><div chart></div></div>',
            scope: {
                data: '<',
                reportTitle: '<',
                layout: '<?',
            },
            link(scope, elem) {
                const beige = $injector.get('COLOR_V3_BEIGE_DARKER');

                function plotlyFont(color, size, family) {
                    const config = {};
                    color = color || 'eggplant';
                    config.color = {
                        eggplant: $injector.get('COLOR_V3_EGGPLANT'),
                        beige,
                    }[color];

                    config.size = size || 15;

                    family = family || 'regular';
                    config.family =
                        {
                            regular: `${$injector.get('FONT_FAMILY_REGULAR')}, sans-serif`,
                            semibold: `${$injector.get('FONT_FAMILY_SEMIBOLD')}, sans-serif`,
                        }[family] || family;

                    return config;
                }

                scope.$watchGroup(['data', 'reportTitle', 'layout'], values => {
                    const data = values[0];
                    if (data) {
                        const plotlyEl = elem.find('[chart]')[0];
                        const layoutOverrides = values[2] || {};

                        const localeCode = Locale.activeCode === 'zh' ? 'zh-CN' : Locale.activeCode;

                        Plotly.setPlotConfig({
                            locale: localeCode,
                        });

                        Plotly.newPlot(
                            plotlyEl,
                            data,
                            angular.extend(
                                {
                                    // https://plot.ly/javascript/reference/#layout
                                    margin: {
                                        l: 60,
                                        r: 0,
                                        b: 60,
                                        t: 30,
                                        pad: 0,
                                    },

                                    // https://plot.ly/javascript/reference/#layout-yaxis
                                    yaxis: {
                                        title: scope.reportTitle,
                                        titlefont: plotlyFont('eggplant', 15, 'semibold'),
                                        tickfont: plotlyFont('beige', 13),
                                        gridcolor: $injector.get('COLOR_V3_BEIGE_MEDIUM'),
                                        zerolinewidth: 2,
                                        zerolinecolor: beige,
                                    },

                                    // https://plot.ly/javascript/reference/#layout-xaxis
                                    xaxis: {
                                        showgrid: false,
                                        tickfont: plotlyFont('beige'),
                                    },

                                    // https://plot.ly/javascript/reference/#layout-legend
                                    legend: {
                                        font: plotlyFont('beige'),
                                    },
                                },
                                layoutOverrides,
                            ),
                            {
                                displaylogo: false,

                                // NOTE: This is unfortunate -- We can add and remove buttons to the modeBar,
                                // but there is no way to override select properties on existing button defaults.
                                modeBarButtonsToRemove: ['sendDataToCloud', 'toggleSpikelines'],
                                // modeBarButtonsToAdd: [],
                                // modeBarButtons: []
                            },
                        );

                        // // FIXME: how do we cancel handlers when regenerating charts?
                        // // do we need to cancel when destroying directive?
                        // // See https://github.com/plotly/plotly.js/issues/107
                        // plotlyEl.on('plotly_click', function(data) {
                        //     console.log('click ', data);
                        // });
                    }
                });
            },
        };
    },
]);
