import angularModule from 'Reports/angularModule/scripts/reports_module';
import { formattedTelephoneOrText } from 'Telephone/TelephoneFormatter';

angularModule.factory('BaseTabularReport', [
    '$injector',
    $injector => {
        const Report = $injector.get('Report');
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('reports.reports');
        const $filter = $injector.get('$filter');
        const $rootScope = $injector.get('$rootScope');
        const ReportDateRange = $injector.get('ReportDateRange');

        // eslint-disable-next-line func-names
        const BaseTabularReport = Report.subclass(function () {
            // FIXME: AClassAbove should support inherited
            const subclassWithoutExtension = this.subclass.bind(this);

            function formatShortDateUTC(timestamp) {
                return timestamp ? $filter('amDateFormat')($filter('amUtc')(1000 * timestamp), 'MMM D YYYY') : '';
            }

            function formatCSVTimeUTC(timestamp) {
                return timestamp
                    ? $filter('amDateFormat')($filter('amUtc')(1000 * timestamp), 'YYYY/MM/DD h:mm a')
                    : '';
            }

            function formatTimeUTC(timestamp) {
                return timestamp
                    ? $filter('amDateFormat')($filter('amUtc')(1000 * timestamp), 'MMM D YYYY HH:mm:ss')
                    : '';
            }

            this.extend({
                dateZoomEnabled: false,
                unlimitedRangeEnabled: true,

                /*
                    Note that the value passed in will be null when there is a row but
                    the value of this particular column is NULL in the db, and the value
                    passed in will be undefined if there is no row at all. (See reports_user_course.html
                    for a case where we call getTabularRowValue and pass in an undefined row)
                */
                formatters: {
                    K: {
                        html: rawValue => rawValue,
                        csv: rawValue => rawValue,
                    },
                    number: {
                        html: rawValue => (angular.isDefined(rawValue) && rawValue !== null ? String(rawValue) : ''),
                        csv: rawValue => (angular.isDefined(rawValue) && rawValue !== null ? String(rawValue) : ''),
                    },
                    numberOrDash: {
                        html: rawValue => (angular.isDefined(rawValue) && rawValue !== null ? String(rawValue) : '-'),
                        csv: rawValue => (angular.isDefined(rawValue) && rawValue !== null ? String(rawValue) : '-'),
                    },
                    clientType: {
                        html: clientType =>
                            ({
                                mobile_web: 'Mobile Web',
                                mobile_app: 'Mobile App',
                                desktop: 'Desktop',
                                unknown: 'Unknown',
                            }[clientType] || '???'),
                        csv: clientType =>
                            ({
                                mobile_web: 'Mobile Web',
                                mobile_app: 'Mobile App',
                                desktop: 'Desktop',
                                unknown: 'Unknown',
                            }[clientType] || '???'),
                    },

                    // accountId is usually an email, but sometimes a phone number and sometimes just the user's id
                    accountIdLink: {
                        html: (accountId, row, ReportKlass) =>
                            `<a ng-click="gotoSection({section: 'user', userId: '${ReportKlass.getTabularRowValue(
                                row,
                                'user_id',
                                'raw',
                            )}'})">${formattedTelephoneOrText(accountId)}</a>`,
                        csv: accountId => accountId,
                    },
                    date: {
                        html: timestamp => formatShortDateUTC(timestamp),
                        csv: timestamp => formatCSVTimeUTC(timestamp),
                    },
                    dateWithDetails: {
                        html: timestamp =>
                            `<span title-full-date="${timestamp}">${formatShortDateUTC(timestamp)}</span>`,
                        csv: timestamp => formatCSVTimeUTC(timestamp),
                    },
                    time: {
                        html: timestamp => formatTimeUTC(timestamp),
                        csv: timestamp => formatCSVTimeUTC(timestamp),
                    },
                    amTimeAgo: {
                        html: timestamp => (timestamp ? $filter('amTimeAgo')($filter('amUtc')(1000 * timestamp)) : ''),
                        csv: timestamp => formatCSVTimeUTC(timestamp),
                    },
                    amDurationFormat: {
                        html: seconds => {
                            if (seconds === 0) {
                                return '0 seconds';
                            }
                            if (seconds) {
                                return $filter('amDurationFormat')(1000 * seconds);
                            }
                            return '';
                        },
                        csv: seconds => +seconds,
                    },
                    percent: {
                        html: float => (float || float === 0 ? `${Math.round(float * 1000) / 10}%` : ''),
                        csv: float => (float || float === 0 ? `${Math.round(float * 1000) / 10}%` : ''),
                    },
                    percentOrDash: {
                        html: float => (float || float === 0 ? `${Math.round(float * 1000) / 10}%` : '-'),
                        csv: float => (float || float === 0 ? `${Math.round(float * 1000) / 10}%` : '-'),
                    },
                    bool: {
                        html: value => (value === true ? '<i class="fa fa-check"></i>' : '-'),
                        csv: value => value,
                    },
                    withLessonLocale: {
                        html(value, row, ReportKlass) {
                            return this.csv(value, row, ReportKlass);
                        },
                        csv: (value, row, ReportKlass) => {
                            let locale;
                            // FIXME: I don't like that we reference rootScope and current USer here, but eh
                            if ($rootScope.currentUser && $rootScope.currentUser.hasSuperReportsAccess) {
                                locale = ReportKlass.getTabularRowValue(row, 'lesson_locale', 'raw');
                            }
                            return locale && locale !== 'en' ? `${value} (${locale})` : value;
                        },
                    },
                },

                subclass(options) {
                    const subclass = subclassWithoutExtension(options);
                    if (!_.some(subclass.availableFilterKlasses)) {
                        subclass.availableFilterKlasses = _.clone(BaseTabularReport.defaultFilterKlasses);
                    }
                    return subclass;
                },

                defaultFilterKlasses: [
                    $injector.get('EmailNameFilter'),
                    $injector.get('SignUpCodeFilter'),
                    $injector.get('InstitutionFilter'),
                    $injector.get('CohortFilter'),
                    $injector.get('RoleFilter'),
                    $injector.get('GroupFilter'),
                ],

                // These are filters that are only useful when we're looking at
                // external users. So, for example, in the editor_lesson_sessions_report,
                // we do not show them.
                externalUserFilterKlasses: [
                    $injector.get('SignUpCodeFilter'),
                    $injector.get('InstitutionFilter'),
                    $injector.get('CohortFilter'),
                    $injector.get('RoleFilter'),
                    $injector.get('GroupFilter'),
                ],

                addColumns(entries) {
                    const self = this;
                    _.forEach(entries, entry => {
                        // use an undefined group name and pass each entry in
                        // alone, so it ends up in a group by itself
                        self.addGroupedColumns(undefined, [entry]);
                    });
                },

                addGroupedColumns(titleKey, entries) {
                    this._addFieldFieldConfigs(entries);
                    const groupTitle = titleKey ? translationHelper.get(titleKey) : undefined;
                    this._addColumnGroup(entries, groupTitle);
                    this._addEntriesToColumns(entries, groupTitle);
                },

                addHiddenCsvOnlyColumns(keys) {
                    const entries = _.map(keys, key => [
                        key,
                        key,
                        undefined,

                        // These columns should be hidden from the UI, but should be visible
                        // in the exported CSV.
                        (_report, _currentUser, forCsv) => !!forCsv,
                    ]);
                    this._addFieldFieldConfigs(entries);
                    this._addEntriesToColumns(entries);
                },

                addHiddenColumns(keys) {
                    // since the fields are not visible in the table,
                    // they do not need a title
                    this._addFieldFieldConfigs(keys.map(k => [k]));
                },

                getTabularRowValue(row, columnKey, format) {
                    format = angular.isDefined(format) ? format : 'html';

                    // see report_user_course.html for an example of when we pass an undefined row in here
                    const rawValue = row ? row[this.INDEXES_FOR_COLUMNS[columnKey]] : undefined;

                    if (format === 'raw') {
                        return rawValue;
                    }
                    const column = _.find(this.COLUMNS, {
                        key: columnKey,
                    });

                    return column.formatter[format](rawValue, row, this);
                },

                _addColumnGroup(entries, groupTitle) {
                    this.COLUMN_GROUPS = this.COLUMN_GROUPS || [];
                    this.COLUMN_GROUPS.push({
                        title: groupTitle || '',
                        size: entries.length,
                    });
                },

                _addEntriesToColumns(entries, groupTitle) {
                    const self = this;
                    this.COLUMNS = this.COLUMNS || [];
                    this.COLUMNS = this.COLUMNS.concat(
                        entries.map(entry => {
                            const formatter = entry[2] ? self.formatters[entry[2]] : self.formatters.K;

                            if (!formatter) {
                                throw new Error(`No formatter found for "${entry[2]}"`);
                            }

                            const columnTitle = translationHelper.get(entry[1]);
                            return {
                                key: entry[0],
                                title: columnTitle,
                                csvTitle: groupTitle ? `${groupTitle} - ${columnTitle}` : columnTitle,
                                formatter,

                                // The 4th argument is a function.  The column will only be
                                // shown if it returns true.  It will be triggered with the
                                // arguments report, currentUser. see users_report.js for an example
                                showIf: entry[3],
                            };
                        }),
                    );
                },

                _addFieldFieldConfigs(entries) {
                    const self = this;
                    this.FIELD_CONFIG = this.FIELD_CONFIG || [];
                    this.FIELD_CONFIG = this.FIELD_CONFIG.concat(entries);
                    self.INDEXES_FOR_COLUMNS = {};
                    self.TITLE_KEYS_FOR_COLUMN = {};
                    self.DEFAULT_FIELDS = _.map(self.FIELD_CONFIG, 0);
                    self.FIELD_CONFIG.forEach((entry, i) => {
                        const column = entry[0];
                        self.INDEXES_FOR_COLUMNS[column] = i;
                        self.TITLE_KEYS_FOR_COLUMN[column] = entry[1];
                    });

                    self.prototype.TITLE_KEYS_FOR_COLUMN = self.TITLE_KEYS_FOR_COLUMN;
                    self.prototype.INDEXES_FOR_COLUMNS = self.INDEXES_FOR_COLUMNS;
                },

                removeExternalUserFilterKlasses() {
                    this.availableFilterKlasses =
                        _.some(this.availableFilterKlasses) || _.clone(BaseTabularReport.defaultFilterKlasses);
                    this.availableFilterKlasses = _.difference(
                        this.availableFilterKlasses,
                        BaseTabularReport.externalUserFilterKlasses,
                    );
                },

                addFilterKlasses(klasses, options = {}) {
                    this.availableFilterKlasses = _.some(this.availableFilterKlasses)
                        ? this.availableFilterKlasses
                        : _.clone(BaseTabularReport.defaultFilterKlasses);

                    const index = options.after
                        ? this.availableFilterKlasses.indexOf(options.after) + 1
                        : this.availableFilterKlasses.length;

                    if (index === -1) {
                        throw new Error('filter defined in after is not included in filterKlasses');
                    }

                    const argsForSplice = [index, 0].concat(klasses);
                    this.availableFilterKlasses.splice(...argsForSplice);
                },
            });

            Object.defineProperty(this.prototype, 'COLUMN_GROUPS', {
                get() {
                    return this.constructor.COLUMN_GROUPS;
                },
            });

            this.setCallback('after', 'copyAttrsOnInitialize', function afterCopyAttrsOnInitialize() {
                this.date_range = this.getDefaultDateRange();
            });

            return {
                getTabularRowValue(row, columnKey, format) {
                    return this.constructor.getTabularRowValue(row, columnKey, format);
                },
                getDefaultDateRange() {
                    return ReportDateRange.new({
                        type: 'all',
                    });
                },
                getColumns(currentUser, forCsv) {
                    const self = this;

                    return _.filter(
                        this.constructor.COLUMNS,
                        column => !column.showIf || column.showIf(self, currentUser, forCsv),
                    );
                },
                getCsvColumns(currentUser) {
                    return this.getColumns(currentUser, true);
                },
            };
        });

        return BaseTabularReport;
    },
]);
