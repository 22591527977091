import angularModule from 'Reports/angularModule/scripts/reports_module';
import moment from 'moment-timezone';

angularModule.factory('ReportDateRange', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const $amDateFormat = $injector.get('$filter')('amDateFormat');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $dateFilter = $injector.get('$filter')('date');

        return Iguana.subclass(function () {
            this.alias('ReportDateRange');

            const translationHelper = new TranslationHelper('reports.reports');

            this.defineSetter('value', function (val) {
                let value = parseInt(val);
                value = Math.max(1, value);
                this.writeKey('value', value);
                return value;
            });

            this.defineSetter('unit', function (val) {
                if (!_.includes(['day', 'week', 'month'], val)) {
                    val = 'day';
                }

                // convert, for example, 'last 14 days' into 'last 2 weeks'
                if (this.unit && this.type === 'last' && this.value) {
                    this.value = this._convertValue(this.value, this.unit, val);
                }

                this.writeKey('unit', val);
                return val;
            });

            this.defineSetter('type', function (val) {
                if (val === this.type) {
                    return val;
                }

                // figure out what the current start time is
                let start;
                if (this.type === 'all') {
                    start = moment(this.now)
                        .subtract(365 * 10, this.unit || 'day')
                        .toDate();
                }
                if (this.type === 'last') {
                    start = moment(this.now)
                        .subtract(this.value || 30, this.unit || 'day')
                        .toDate();
                } else {
                    start = this.startTime || moment(this.now).subtract(30, 'days').toDate();
                }

                // change the actual value of type
                this.writeKey('type', val);

                // default unit to 'day' when using the
                // 'last' type
                if (val === 'last') {
                    this.unit = this.unit || 'day';
                }

                // If switching to the 'last' type, set the value.
                if (val === 'last') {
                    const daysSinceStart = (this.now - start) / (24 * 60 * 60 * 1000);
                    this.unit = this.unit || 'day';
                    this.value = this._convertValue(daysSinceStart, 'day', this.unit);
                    this.startTime = undefined;
                    this.finishTime = undefined;
                }

                // If switching to 'since' or 'between', set the
                // start and finish times
                else if (val === 'since' || val === 'between') {
                    this.value = undefined;
                    this.startTime = start;
                    if (val === 'between') {
                        this.finishTime = this.finishTime || this.now;
                    } else {
                        this.finishTime = undefined;
                    }
                }
            });

            Object.defineProperty(this.prototype, 'startTime', {
                get() {
                    if (this.type === 'last') {
                        return moment(this.now).subtract(this.value, this.unit).toDate();
                    }
                    return this._getDate('start_day', '$$startTime');
                },
                set(val) {
                    if (this.type === 'last' && val) {
                        throw new Error('startTime is not settable with the "last" type.');
                    }
                    return this._setDate(
                        'start_day',
                        '$$startTime',
                        val,
                        undefined,
                        this.type === 'between' ? this.finishTime : this.now,
                    );
                },
            });

            Object.defineProperty(this.prototype, 'finishTime', {
                get() {
                    if (this.type === 'last' || this.type === 'since') {
                        return this.now;
                    }
                    return this._getDate('finish_day', '$$finishTime');
                },
                set(val) {
                    if (this.type !== 'between' && val) {
                        throw new Error('finishTime is only settable with the "between" type.');
                    }
                    return this._setDate('finish_day', '$$finishTime', val, this.startTime, this.now);
                },
            });

            Object.defineProperty(this.prototype, 'label', {
                get() {
                    if (this.type === 'all') {
                        return translationHelper.get('date_range_label_show_all');
                    }
                    if (this.type === 'last') {
                        return translationHelper.get(
                            this.value === 1
                                ? `date_range_label_last_${this.unit}_singular`
                                : `date_range_label_last_${this.unit}_plural`,
                            {
                                value: this.value,
                            },
                        );
                    }
                    if (this.type === 'since') {
                        return translationHelper.get('date_range_label_since', {
                            start: $amDateFormat(this.startTime, this.DATE_FORMAT),
                        });
                    }
                    if (this.type === 'between') {
                        return translationHelper.get('date_range_label_between', {
                            start: $amDateFormat(this.startTime, this.DATE_FORMAT),
                            end: $amDateFormat(this.finishTime, this.DATE_FORMAT),
                        });
                    }
                    throw new Error('Unexpected type');
                },
            });

            // getter to override in tests
            Object.defineProperty(this.prototype, 'now', {
                get() {
                    return new Date();
                },
                configurable: true,
            });

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.type = this.type || 'last';
            });

            function dateString(date) {
                return $dateFilter(date, 'yyyy-MM-dd');
            }

            return {
                DATE_FORMAT: 'MMM D',

                _getDate(persistedProp, tempProp) {
                    if (!this[persistedProp]) {
                        return undefined;
                    }

                    // if we already have a value for the tempProp, and
                    // it still matches the key in the data, then return it
                    // rather than creating a new instance
                    if (this[tempProp] && dateString(this[tempProp]) === this[persistedProp]) {
                        return this[tempProp];
                    }
                    this[tempProp] = moment(this[persistedProp]).toDate(); // this is different from new Date(this[persistedProp]).  This works. That doesn't
                    return this[tempProp];
                },

                _setDate(persistedProp, tempProp, val, min, max) {
                    if (val > max) {
                        return;
                    }

                    if (val < min) {
                        return;
                    }

                    this[tempProp] = val;
                    if (val) {
                        this[persistedProp] = dateString(val.getTime());
                    } else {
                        this[persistedProp] = undefined;
                    }

                    return val;
                },

                _convertValue(value, sourceUnit, targetUnit) {
                    const scales = {
                        day: 1,
                        week: 7,
                        month: 30,
                    };
                    const currentScale = scales[sourceUnit];
                    const newScale = scales[targetUnit];
                    const delta = currentScale / newScale;
                    return Math.max(1, Math.round(value * delta));
                },
            };
        });
    },
]);
