import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('ListFilter', [
    '$injector',
    $injector => {
        const ReportFilter = $injector.get('ReportFilter');
        const Report = $injector.get('Report');

        return ReportFilter.subclass(function () {
            this.alias('ListFilter');

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.value = this.value || [];
            });

            this.extend({
                filterBaseType: 'list',
                ensureOptions(reportAlias, user) {
                    const self = this;
                    const report = Report.reportTypes[reportAlias] || Report;
                    return report.getFilterOptions(this.alias(), user).then(options => {
                        self.options = options;
                        return options;
                    });
                },
            });
        });
    },
]);
