import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('UserIdFilter', [
    '$injector',
    $injector => {
        const ListFilter = $injector.get('ListFilter');

        return ListFilter.subclass(function () {
            this.alias('UserIdFilter');
        });
    },
]);
