import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('CourseCompletedFilter', [
    '$injector',
    $injector => {
        const ListFilter = $injector.get('ListFilter');

        return ListFilter.subclass(function () {
            this.alias('CourseCompletedFilter');
            this.$$placeholderKey = 'filter_placeholder_courses_completed';
            this.availableToInstitutionalReportsViewers = true;
        });
    },
]);
