import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.directive('titleFullDate', [
    '$injector',
    function factory($injector) {
        const $filter = $injector.get('$filter');

        return {
            restrict: 'A',
            scope: {},
            link(scope, element, attrs) {
                const timestamp = attrs.titleFullDate; // time in seconds
                if (timestamp && timestamp > 0) {
                    element.prop(
                        'title',
                        `${$filter('amDateFormat')(
                            $filter('amUtc')(1000 * timestamp),
                            'dddd, MMMM Do YYYY, h:mm a',
                        )} UTC`,
                    );
                }
            },
        };
    },
]);
