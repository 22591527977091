import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('GroupFilter', [
    '$injector',
    $injector => {
        const ListFilter = $injector.get('ListFilter');

        return ListFilter.subclass(function () {
            this.alias('GroupFilter');
            this.$$placeholderKey = 'filter_placeholder_groups';
            this.availableToInstitutionalReportsViewers = true;
        });
    },
]);
