import angularModule from 'Reports/angularModule/scripts/reports_module';
import template from 'Reports/angularModule/views/reports_overview.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('reportsOverview', [
    '$injector',

    function factory($injector) {
        const HasCurrentReportMixin = $injector.get('HasCurrentReportMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                availableReportTypes: '<?', // only used in tests
            },
            link(scope) {
                HasCurrentReportMixin.onLink(scope, 'ActiveUsersReport');
                scope.availableReportTypes = scope.availableReportTypes || ['ActiveUsersReport', 'TimeOnTaskReport'];
            },
        };
    },
]);
