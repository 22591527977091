import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('ReportFilter', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.alias('ReportFilter');
            this.setSciProperty('filter_type');

            this.extend({
                filterBaseType: 'FIXME OVERRIDE FILTER BASE TYPE', // OVERRIDE in subclasses
                availableForUser(user) {
                    return this.availableToInstitutionalReportsViewers || user.hasSuperReportsAccess;
                },
            });

            return {};
        });
    },
]);
