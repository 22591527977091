import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('EditorLessonSessionsReport', [
    '$injector',
    $injector => {
        const BaseTabularReport = $injector.get('BaseTabularReport');
        const ReportDateRange = $injector.get('ReportDateRange');

        return BaseTabularReport.subclass(function () {
            // Note: see server report model for reference of expected fields
            this.addColumns([
                ['user_name', 'th_name'],
                ['account_id', 'th_account_id', 'accountIdLink'],
                ['lesson_title', 'th_lesson', 'withLessonLocale'], // fixme: refactor processor to use withLessonLocale for consistency
                ['started_at', 'th_started', 'time'],
                ['total_time', 'th_total_time', 'amDurationFormat'],
                ['save_count', 'th_save_count', 'number'],
            ]);

            this.addHiddenColumns(['user_id', 'lesson_locale']);

            // must set _alias so reports_overview_dir can setup the selectize
            this._alias = this.alias('EditorLessonSessionsReport');
            this.$$titleKey = 'report_title_editor_lesson_sessions';

            this.availableGroupByIdentifiers = [];

            this.removeExternalUserFilterKlasses();
            this.addFilterKlasses([$injector.get('LessonLocaleFilter'), $injector.get('LessonFilter')]);

            return {
                getDefaultDateRange() {
                    return ReportDateRange.new({
                        unit: 'day',
                        type: 'last',
                        value: 1,
                    });
                },
            };
        });
    },
]);
