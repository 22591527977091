import angularModule from 'Reports/angularModule/scripts/reports_module';

angularModule.factory('BoolFilter', [
    '$injector',
    $injector => {
        const ReportFilter = $injector.get('ReportFilter');

        return ReportFilter.subclass(function () {
            this.alias('BoolFilter');

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.value = this.value || [];
            });

            this.extend({
                filterBaseType: 'bool',
                ensureOptions() {
                    this.options = [
                        {
                            value: 'true',
                            text: 'Yes',
                        },
                        {
                            value: 'false',
                            text: 'No',
                        },
                    ];

                    return this.options;
                },
            });
        });
    },
]);
